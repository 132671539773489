//Menu mobile
const menuToggler = document.getElementById("menuToggle");
const body = document.getElementsByTagName("body")[0];

if (menuToggler) {
  menuToggler.addEventListener("click", openMenu);

  function openMenu() {
    body.classList.toggle("menuToggle-opened");
  }
}

// slider products categ
const slider = document.getElementById('products_selection_list');
if (slider) {
  const prev = document.getElementById('prev');
  const next = document.getElementById('next');

  if (next) {
    next.onclick = function() {
      slider.scrollLeft += 100;
    };

    prev.onclick = function() {
      slider.scrollLeft -= 100;
    };
  }
}
