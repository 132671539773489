(function($){
  // console.log('coucou');
  $('input[type="number"]').each(function () {
      // Cache the number of options
      var $this = $(this);

      const $wrapper = $('<div class="custom-input-number-wrapper">');
      const $btLess = $('<button class="custom-input-number-btLess input">');
      const $btMore = $('<button class="custom-input-number-btLess input">');

      $btLess.text('-');
      $btMore.text('+');

      $this.after($wrapper);
      $wrapper.append($this);

      $this.before($btLess);
      $this.after($btMore);


      $btLess.click(function(e){
        if($this.val() >0){
          $this.val(parseInt($this.val()) -1);
        }

        e.preventDefault();
        return false;
      });
      $btMore.click(function(e){
        $this.val(parseInt($this.val()) +1);

        e.preventDefault();
        return false;
      });
  });
})(jQuery);
