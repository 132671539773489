(function($) {

  if ($(window).width() < 1024) {
    //VAR
    const searchIcon = $(".search-form");
    const menuIcon = $("#menuToggle");
    const searchField = $('.search-field');
    let isActive = false;
    let otherLinks = $('.menu-top-nav-container');
    const closeBtn = $('<button class="close">X</button>');

    const openInput = function() {
      isActive=true;
      menuIcon.addClass('isHidden');
      otherLinks.addClass('isHidden');
      searchIcon.addClass('isClicked');
      searchField.focus();
    };
    const closeInput = function() {
      isActive=false;
      menuIcon.removeClass('isHidden');
      otherLinks.removeClass('isHidden');
      searchIcon.removeClass('isClicked');
    };


// Un clic pour afficher la barre de recherche. Au deuxième click, la recherche s'effectue
    searchIcon.one("click",function(e) {
      e.preventDefault();
      openInput();
      closeBtn.appendTo(searchIcon);
    })

    closeBtn.click(function() {
      closeInput();
      closeBtn.remove();
    })

  }
})(jQuery);
