(function($){
  var $elCuissons = $('.field-mode-cuisson-container .icon-btn');
  let dataCussion = {};

  $elCuissons.each(function(){
    var values = $(this).data('values');
    values = values.split(',');
    for(let i in values){
      console.log(values[i])
        if(dataCussion[values[i]] == undefined){
          dataCussion[values[i]] = [$(this)];
        }else{
          dataCussion[values[i]].push($(this));
        }
    }
  });

  $('#_mcmp_sbw_quantity_select').change(function(){
    setModeCuisson($(this).val());
  });
  setModeCuisson($('#_mcmp_sbw_quantity_select').val());

  function setModeCuisson(value){
    if(!value){
      return ;
    }
    value = value.split(';');

    //set mode cuisson
    $elCuissons = $('.field-mode-cuisson-container .mode-cuisson--selected').removeClass('mode-cuisson--selected');
    for(let i in dataCussion[value[1]]){
      dataCussion[value[1]][i].closest('.iconWrapper').addClass('mode-cuisson--selected');
    }


    //set nbpersonne
    $('.field-nbpersonne-container .nbpersonne-value-selected').removeClass('nbpersonne-value-selected');
    $('.field-nbpersonne-container [data-weight="'+value[0]+'"]').addClass('nbpersonne-value-selected');

    if($('.field-nbpersonne-container .nbpersonne-value').length == 1){
      $('.field-nbpersonne-container .nbpersonne-value').addClass('nbpersonne-value-selected');
    }
    if($('.field-nbpersonne-container .nbpersonne-value').length == 0){
      $('.field-nbpersonne-container').hide();
    }
  }
})(jQuery);
