(function($) {

  if ($(window).width() < 1024) {
    //VAR
    let closeButton = $("#menuToggle");
    let siteNavigation = $(".site-navigation");
    let mainMenu = $('.menu');
    let mainMenuItemLink = $('#menu-main-nav > .menu-item > a');
    let subMenu = $('.sub-menu');
    let subMenuItem = $('.sub-menu .menu-item');
    let subMenuItemLink = $('.sub-menu .menu-item > a');
    // console.log(subMenuItemLink);

    let isOpen = false;
    let openSubmenu = function() {
      subMenu.addClass('is-active');
      siteNavigation.addClass('has-submenu-open');
      isOpen = true;
    };
    let closeSubmenu = function() {
      subMenu.removeClass('is-active');
      siteNavigation.removeClass('has-submenu-open');
      isOpen = false;
    };

    //MAIN


    // Si sous menu, alors ajoute class au click
    mainMenuItemLink.click(function(event) {
      if ($(this).parent('li').children('ul').length > 0) {
        event.preventDefault();
        event.stopPropagation();
        siteNavigation.addClass('has-submenu-open');
        $(this).parent('li').children('ul').addClass('is-active');
      }
    });

    subMenuItemLink.click(function(e) {
      e.stopPropagation();
    });

    siteNavigation.click(function(event) {
      event.stopPropagation();
      closeSubmenu();
    });


    closeButton.click(function() {
      closeSubmenu();
    })
  }
})(jQuery);
