(function($){

// console.log('yo maggle');
//VAR
let slideIndex = 0;
let dots = document.getElementsByClassName("slideshow-dot");
let slides = document.querySelectorAll(".slideshow-slide,.wp-block-trollier-testimonial");
let nbPerSlide = 1;

//MAIN

if($('.wp-block-trollier-testimonial-slider').length){
  nbPerSlide = 2;
}
console.log(nbPerSlide, 'n');
if(dots.length == 0){
  generateDot();
}

if(dots.length)
  showSlides(slideIndex);

let addClickListenerToDot = function(index){
  dots[index].className = dots[index].className.replace(" active", "");
  dots[index].addEventListener("click", function(){
    currentSlide(index);
  });
}

for (i = 0; i < dots.length; i++) {
  addClickListenerToDot(i);
}

//FONCTION UTILS
function showSlides(n) {
  let i;
// Je loop sur le nombre d'occurences de dots pour lui assigner un event listener click qui déclanche currentSlider

  // if (n > slides.length) {
  //   slideIndex = 1
  // }
  // if (n < 1) {
  //   slideIndex = slides.length
  // }
  for (i = 0; i < slides.length; i++) {
    slides[i].style.display = "none";
  }

  let slideStart = (slideIndex)*nbPerSlide;
  let slideEnd = slideStart + (nbPerSlide) -1;
  console.log(slideIndex,slideStart,slideEnd)
  for(y = slideStart; y <= slideEnd; y++){
    if(y < slides.length)
      slides[y].style.display = "block";
  }
  $('.slideshow-dots-container .active').removeClass('active');
  dots[slideIndex].className += " active";
}


//Thumbnail image controls
function currentSlide(n) {
  showSlides(slideIndex = n);
}

function generateDot(){
  var numberOfDots = Math.ceil(slides.length / nbPerSlide);

  for(let i =0; i < numberOfDots;i++){
    $('.slideshow-dots-container').append('<span class="slideshow-dot">')
  }
  let dots = document.getElementsByClassName("slideshow-dot");

}

})(jQuery);
